import React, { useEffect, useState } from "react";
import InnerHTML from "dangerously-set-html-content";

const InlineScriptComponent = ({ node }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  if (!hasLoaded) return <></>;

  return <InnerHTML html={node.text} allowRerender={true} />;
};

export const IssuuEmbed = ({ node }) => {
  return <InlineScriptComponent node={node} />;
};
