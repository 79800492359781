import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { IssuuEmbed } from "../components/embeds/issuu-embed";

// SEO
import { PageSEO } from "../components/seo/page-seo";

// Components

const PageContainer = styled.div`
  padding: 50px 0 0 0;
`;

const Grid = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 2fr;
  // grid-gap: 20px;

  // & .left-column {
  //   & .sticky {
  //     position: sticky;
  //     top: 20px;

  //     min-height: calc(100vh - 40px);

  //     display: flex;
  //     flex-direction: row;
  //     flex-wrap: wrap;

  //     @media (max-width: 767px) {
  //       display: block;

  //       min-height: 100%;
  //     }
  //   }
  // }

  // @media (max-width: 1020px) {
  //   grid-template-columns: 290px 1fr;
  // }
`;

const Container = styled.div`
  max-width: 675px;
  margin: 0 auto;

  & h1 {
    // font-size: 20px;
    // line-height: 24px;
    margin: 0;

    font-size: 16px;
    line-height: 1.15;
  }

  & h2 {
    // font-size: 18px;
    // line-height: 24px;

    font-size: 16px;
    line-height: 1.15;
    margin: 0;
  }
`;

const Page = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`page`);
  }, []);

  const content = data.prismicPage.data.body.map((content, index) => {
    if (content.slice_type === "flipbook_embed") {
      return (
        <div key={`single_item_${index}`} className="">
          <IssuuEmbed node={content.primary.embed_code} />
        </div>
      );
    } else {
      return (
        <div key={`single_item_${index}`} className="single-text-box">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }
  });

  return (
    <>
      <PageSEO
        title={
          data.prismicPage.data.seo_title !== null
            ? data.prismicPage.data.seo_title
            : data.prismicPage.data.title.text
        }
        image={
          data.prismicPage.data.seo_image !== null
            ? data.prismicPage.data.seo_image.url
            : null
        }
        description={data.prismicPage.data.seo_description}
        url={`https://mcmullanstudio.com${data.prismicPage.url}`}
      />

      <PageContainer>
        <Grid>
          <div className="center-column">
            <Container className="inline-link">{content}</Container>
          </div>
        </Grid>
      </PageContainer>
    </>
  );
};

export default withPreview(Page);

export const query = graphql`
  query Page($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      url
      data {
        title {
          html
          text
        }
        seo_title
        seo_image {
          url
        }
        seo_description
        body {
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyFlipbookEmbed {
            id
            slice_type
            primary {
              embed_code {
                text
              }
            }
          }
        }
      }
    }
  }
`;
